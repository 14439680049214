<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <pendientes />
      </v-col>
      <v-col cols="6">
        <priorizar />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import pendientes from '@/views/crmpro/agendar/pendientes.vue'
import priorizar from '@/views/crmpro/agendar/priorizar.vue'
export default {
  components: {
    pendientes,
    priorizar
  },
}
</script>

<style>

</style>