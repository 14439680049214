<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar
            height="40"
            dense
            flat
            color="green"
            dark
          >
            <h3>Lista de pendientes</h3>
          </v-toolbar>
          <v-data-table
            dense
            :headers="headers"
            :items="proyectos"
          >
            <template v-slot:item="{ item }">
              <tr @dblclick="editar(item)">
                <td>{{ item.pendiente }}</td>
                <td>{{ item.estado }}</td>
                <td>{{ item.fecha }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      proyectos: [],
      headers: [

        { text: 'Pendiente', value: 'pendiente'},
        { text: 'Estado', value: 'estado' },
        { text: 'fecha', value: 'fecha' },
      ],


      
      // json_fields: {
      //   Estatus: "completo",
      //   Id: "idproyecto",
      //   FechaInicio: "inicio",
      //   FechaFin: "meta",

      //   NombreProyecto: "nomproy",
      //   NumCliente: "numcli",
      //   NumProyecto: "numproy",
      //   Observaciones: "obs",
      //   Precio: "precio",
      // },
      // json_meta: [
      //   [
      //     {
      //       key: "charset",
      //       value: "utf-8",
      //     },
      //   ],
      // ],
      // json_data: [],
    }
  },
}
</script>